:root,
:root .white-theme {
    --offWhite: #fafafa;
    --lightGray: #ededed;
    --midGray: #888b8d;
    --darkGray: #63666a;
    --blue: #0093de;
    --green: #3f9c35;
    --olive: #a2a569;
    --orange: #e87722;
    --purple: #9f5cc0;
    --teal: #34a798;
    --red: #e40046;
    --stagingThemeColor: var(--midGray);
    --themeColor: var(--blue);
    --highlight: rgb(0, 147, 222, 0.25);
    --menuText-Icons: #fff;
    --menuText-IconsHover: #fff;
    --subMenuText: #fff;
    --menuBarColor: #0093de;
    --menuBarBorder: #e0e0e0;
    --menuBackgroundColor: var(--themeColor);
    --menuBorder: rgba(255, 255, 255, 0.4);
    --menuTitle-bg: rgba(255, 255, 255, 0.2);
    --activeTabColor: var(--themeColor);
    --menuLink-active: rgba(0, 147, 222, 0.8);
    --menuLink-hover: rgba(0, 0, 0, 0.1);
    --menuSubheaderBorder: rgba(255, 255, 255, 0.4);
    --subMenuLink-active: rgba(255, 255, 255, 0.2);
    --subMenuLink-hover: rgba(255, 255, 255, 0.4);
    --mainTextColor: #2d2d2d;
    --mainLinkColor: var(--themeColor);
    --mainBackgroundColor: #fafafa;
    --gridBorder: #e0e0e0;
    --gridHoverColor: #f5f5f5;
}

:root .white-theme .dx-icon-edit {
    content: url('../../assets/images/icon-edit_16px_blue.svg') !important;
}

:root .white-theme .dx-icon-redo,
:root .white-theme .dx-icon-save {
    content: url('../../assets/images/icon-save_blue.svg') !important;
}

:root .white-theme .dx-icon-revert {
    content: url('../../assets/images/icon-cancel_blue.svg') !important;
}

:root .white-theme .dx-icon-trash {
    content: url('../../assets/images/icon-trash_16px_blue.svg') !important;
}

:root .gray-theme {
    --themeColor: var(--darkGray);
    --highlight: rgb(99, 102, 106, 0.25);
    --menuText-Icons: #fff;
    --menuText-IconsHover: #fff;
    --subMenuText: #fff;
    --menuBarColor: var(--themeColor);
    --menuBarBorder: var(--themeColor);
    --menuBackgroundColor: var(--themeColor);
    --menuBorder: rgba(255, 255, 255, 0.4);
    --menuTitle-bg: rgba(255, 255, 255, 0.2);
    --activeTabColor: #fff;
    --menuLink-active: rgba(255, 255, 255, 0.2);
    --menuLink-hover: rgba(255, 255, 255, 0.4);
    --menuSubheaderBorder: rgba(255, 255, 255, 0.4);
    --subMenuLink-active: rgba(255, 255, 255, 0.2);
    --subMenuLink-hover: rgba(255, 255, 255, 0.4);
    --mainTextColor: #2d2d2d;
    --mainLinkColor: var(--themeColor);
}

:root .gray-theme .dx-icon-edit {
    content: url('../../assets/images/icon-edit_16px_gray.svg') !important;
}

:root .gray-theme .dx-icon-redo,
:root .gray-theme .dx-icon-save {
    content: url('../../assets/images/icon-save_gray.svg') !important;
}

:root .gray-theme .dx-icon-revert {
    content: url('../../assets/images/icon-cancel_gray.svg') !important;
}

:root .gray-theme .dx-icon-trash {
    content: url('../../assets/images/icon-trash_16px_gray.svg') !important;
}

:root .blue-theme {
    --themeColor: var(--blue);
    --highlight: rgb(0, 147, 222, 0.25);
    --menuText-Icons: #fff;
    --menuText-IconsHover: #fff;
    --subMenuText: #fff;
    --menuBarColor: var(--themeColor);
    --menuBarBorder: var(--themeColor);
    --menuBackgroundColor: var(--themeColor);
    --menuBorder: rgba(255, 255, 255, 0.4);
    --menuTitle-bg: rgba(255, 255, 255, 0.2);
    --activeTabColor: #fff;
    --menuLink-active: rgba(255, 255, 255, 0.2);
    --menuLink-hover: rgba(255, 255, 255, 0.4);
    --menuSubheaderBorder: rgba(255, 255, 255, 0.4);
    --subMenuLink-active: rgba(255, 255, 255, 0.2);
    --subMenuLink-hover: rgba(255, 255, 255, 0.4);
    --mainTextColor: #2d2d2d;
    --mainLinkColor: var(--themeColor);
}

:root .blue-theme .dx-icon-edit {
    content: url('../../assets/images/icon-edit_16px_blue.svg') !important;
}

:root .blue-theme .dx-icon-redo,
:root .blue-theme .dx-icon-save {
    content: url('../../assets/images/icon-save_blue.svg') !important;
}

:root .blue-theme .dx-icon-revert {
    content: url('../../assets/images/icon-cancel_blue.svg') !important;
}

:root .blue-theme .dx-icon-trash {
    content: url('../../assets/images/icon-trash_16px_blue.svg') !important;
}

:root .green-theme {
    --themeColor: var(--green);
    --highlight: rgb(63, 156, 53, 0.25);
    --menuText-Icons: #fff;
    --menuText-IconsHover: #fff;
    --subMenuText: #fff;
    --menuBarColor: var(--themeColor);
    --menuBarBorder: var(--themeColor);
    --menuBackgroundColor: var(--themeColor);
    --menuBorder: rgba(255, 255, 255, 0.4);
    --menuTitle-bg: rgba(255, 255, 255, 0.2);
    --activeTabColor: #fff;
    --menuLink-active: rgba(255, 255, 255, 0.2);
    --menuLink-hover: rgba(255, 255, 255, 0.4);
    --menuSubheaderBorder: rgba(255, 255, 255, 0.4);
    --subMenuLink-active: rgba(255, 255, 255, 0.2);
    --subMenuLink-hover: rgba(255, 255, 255, 0.4);
    --mainTextColor: #2d2d2d;
    --mainLinkColor: var(--themeColor);
}

:root .green-theme .dx-icon-edit {
    content: url('../../assets/images/icon-edit_16px_green.svg') !important;
}

:root .green-theme .dx-icon-redo,
:root .green-theme .dx-icon-save {
    content: url('../../assets/images/icon-save_green.svg') !important;
}

:root .green-theme .dx-icon-revert {
    content: url('../../assets/images/icon-cancel_green.svg') !important;
}

:root .green-theme .dx-icon-trash {
    content: url('../../assets/images/icon-trash_16px_green.svg') !important;
}

:root .olive-theme {
    --themeColor: var(--olive);
    --highlight: rgb(162, 165, 105, 0.25);
    --menuText-Icons: #fff;
    --menuText-IconsHover: #fff;
    --subMenuText: #fff;
    --menuBarColor: var(--themeColor);
    --menuBarBorder: var(--themeColor);
    --menuBackgroundColor: var(--themeColor);
    --menuBorder: rgba(255, 255, 255, 0.4);
    --menuTitle-bg: rgba(255, 255, 255, 0.2);
    --activeTabColor: #fff;
    --menuLink-active: rgba(255, 255, 255, 0.2);
    --menuLink-hover: rgba(255, 255, 255, 0.4);
    --menuSubheaderBorder: rgba(255, 255, 255, 0.4);
    --subMenuLink-active: rgba(255, 255, 255, 0.2);
    --subMenuLink-hover: rgba(255, 255, 255, 0.4);
    --mainTextColor: #2d2d2d;
    --mainLinkColor: var(--themeColor);
}

:root .olive-theme .dx-icon-edit {
    content: url('../../assets/images/icon-edit_16px_olive.svg') !important;
}

:root .olive-theme .dx-icon-redo,
:root .olive-theme .dx-icon-save {
    content: url('../../assets/images/icon-save_olive.svg') !important;
}

:root .olive-theme .dx-icon-revert {
    content: url('../../assets/images/icon-cancel_olive.svg') !important;
}

:root .olive-theme .dx-icon-trash {
    content: url('../../assets/images/icon-trash_16px_olive.svg') !important;
}

:root .orange-theme {
    --themeColor: var(--orange);
    --highlight: rgb(232, 119, 34, 0.25);
    --menuText-Icons: #fff;
    --menuText-IconsHover: #fff;
    --subMenuText: #fff;
    --menuBarColor: var(--themeColor);
    --menuBarBorder: var(--themeColor);
    --menuBackgroundColor: var(--themeColor);
    --menuBorder: rgba(255, 255, 255, 0.4);
    --menuTitle-bg: rgba(255, 255, 255, 0.2);
    --activeTabColor: #fff;
    --menuLink-active: rgba(255, 255, 255, 0.2);
    --menuLink-hover: rgba(255, 255, 255, 0.4);
    --menuSubheaderBorder: rgba(255, 255, 255, 0.4);
    --subMenuLink-active: rgba(255, 255, 255, 0.2);
    --subMenuLink-hover: rgba(255, 255, 255, 0.4);
    --mainTextColor: #2d2d2d;
    --mainLinkColor: var(--themeColor);
    --mainLinkColorHover: lighten(var(--themeColor), 1.2%);
}

:root .orange-theme .dx-icon-edit {
    content: url('../../assets/images/icon-edit_16px_orange.svg') !important;
}

:root .orange-theme .dx-icon-redo,
:root .orange-theme .dx-icon-save {
    content: url('../../assets/images/icon-save_orange.svg') !important;
}

:root .orange-theme .dx-icon-revert {
    content: url('../../assets/images/icon-cancel_orange.svg') !important;
}

:root .orange-theme .dx-icon-trash {
    content: url('../../assets/images/icon-trash_16px_orange.svg') !important;
}

:root .purple-theme {
    --themeColor: var(--purple);
    --highlight: rgb(159, 92, 192, 0.25);
    --menuText-Icons: #fff;
    --menuText-IconsHover: #fff;
    --subMenuText: #fff;
    --menuBarColor: var(--themeColor);
    --menuBarBorder: var(--themeColor);
    --menuBackgroundColor: var(--themeColor);
    --menuBorder: rgba(255, 255, 255, 0.4);
    --menuTitle-bg: rgba(255, 255, 255, 0.2);
    --activeTabColor: #fff;
    --menuLink-active: rgba(255, 255, 255, 0.2);
    --menuLink-hover: rgba(255, 255, 255, 0.4);
    --menuSubheaderBorder: rgba(255, 255, 255, 0.4);
    --subMenuLink-active: rgba(255, 255, 255, 0.2);
    --subMenuLink-hover: rgba(255, 255, 255, 0.4);
    --mainTextColor: #2d2d2d;
    --mainLinkColor: var(--themeColor);
}

:root .purple-theme .dx-icon-edit {
    content: url('../../assets/images/icon-edit_16px_purple.svg') !important;
}

:root .purple-theme .dx-icon-redo,
:root .purple-theme .dx-icon-save {
    content: url('../../assets/images/icon-save_purple.svg') !important;
}

:root .purple-theme .dx-icon-revert {
    content: url('../../assets/images/icon-cancel_purple.svg') !important;
}

:root .purple-theme .dx-icon-trash {
    content: url('../../assets/images/icon-trash_16px_purple.svg') !important;
}

:root .teal-theme {
    --themeColor: var(--teal);
    --highlight: rgb(52, 167, 152, 0.25);
    --menuText-Icons: #fff;
    --menuText-IconsHover: #fff;
    --subMenuText: #fff;
    --menuBarColor: var(--themeColor);
    --menuBarBorder: var(--themeColor);
    --menuBackgroundColor: var(--themeColor);
    --menuBorder: rgba(255, 255, 255, 0.4);
    --menuTitle-bg: rgba(255, 255, 255, 0.2);
    --activeTabColor: #fff;
    --menuLink-active: rgba(255, 255, 255, 0.2);
    --menuLink-hover: rgba(255, 255, 255, 0.4);
    --menuSubheaderBorder: rgba(255, 255, 255, 0.4);
    --subMenuLink-active: rgba(255, 255, 255, 0.2);
    --subMenuLink-hover: rgba(255, 255, 255, 0.4);
    --mainTextColor: #2d2d2d;
    --mainLinkColor: var(--themeColor);
}

:root .teal-theme .dx-icon-edit {
    content: url('../../assets/images/icon-edit_16px_teal.svg') !important;
}

:root .teal-theme .dx-icon-redo,
:root .teal-theme .dx-icon-save {
    content: url('../../assets/images/icon-save_teal.svg') !important;
}

:root .teal-theme .dx-icon-revert {
    content: url('../../assets/images/icon-cancel_teal.svg') !important;
}

:root .teal-theme .dx-icon-trash {
    content: url('../../assets/images/icon-trash_16px_teal.svg') !important;
}

:root .staging-theme {
    --themeColor: var(--stagingThemeColor);
    --highlight: rgb(136, 139, 141, 0.25);
    --menuText-Icons: #fff;
    --menuText-IconsHover: #fff;
    --subMenuText: #fff;
    --menuBarColor: var(--themeColor);
    --menuBarBorder: var(--themeColor);
    --menuBackgroundColor: var(--themeColor);
    --menuBorder: rgba(255, 255, 255, 0.4);
    --menuTitle-bg: rgba(255, 255, 255, 0.2);
    --activeTabColor: #fff;
    --menuLink-active: rgba(255, 255, 255, 0.2);
    --menuLink-hover: rgba(255, 255, 255, 0.4);
    --menuSubheaderBorder: rgba(255, 255, 255, 0.4);
    --subMenuLink-active: rgba(255, 255, 255, 0.2);
    --subMenuLink-hover: rgba(255, 255, 255, 0.4);
    --mainTextColor: #2d2d2d;
    --mainLinkColor: var(--themeColor);
}

:root .staging-theme .dx-icon-edit {
    content: url('../../assets/images/icon-edit_16px_gray.svg') !important;
}

:root .staging-theme .dx-icon-redo,
:root .staging-theme .dx-icon-save {
    content: url('../../assets/images/icon-save_gray.svg') !important;
}

:root .staging-theme .dx-icon-revert {
    content: url('../../assets/images/icon-cancel_gray.svg') !important;
}

:root .staging-theme .dx-icon-trash {
    content: url('../../assets/images/icon-trash_16px_gray.svg') !important;
}

.dx-svg-icon {
    max-height: 18px !important;
    vertical-align: middle;
    position: relative;
}


/* this is to handle the icon in the managed layouts grid */

#layout-manager-dialog-grid .dx-svg-icon {
    margin-top: -5px;
}

.dx-svg-icon svg {
    margin: 0 !important;
    padding: 0 !important;
}

.clockIcon {
    fill: var(--themeColor) !important;
    transform: translateY(-3px) !important;
}

.clockIcon.disabled {
    fill: var(--mainTextColor) !important;
    opacity: 0.3;
}

.dx-icon:hover .clockIcon {
    fill: var(--themeColor) !important;
    opacity: 1;
}

.highlight {
    background-color: var(--highlight);
}

h1.headerTitle span {
    font-weight: 300;
}

h1.headerTitle {
    color: var(--themeColor)!important;
    font-size: 14px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-display: swap;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: .01em!important;
    text-transform: none!important;
}

.headerTitle,
.detail-header-link,
.download-link {
    color: var(--mainLinkColor) !important;
}

.detail-header-link:hover,
.download-link:hover {
    filter: brightness(110%);
    text-decoration: none !important;
}

.headerAutocomplete.Mui-focused fieldset {
    border-color: var(--themeColor) !important;
}

.MuiBadge-colorPrimary {
    background: var(--mainLinkColor) !important;
}

.dxc-item {
    fill: var(--mainTextColor) !important;
}

a span.MuiBreadcrumbs-separator,
.MuiBreadcrumbs-ol li {
    color: rgba(0, 0, 0, 0.54) !important;
}

.MuiBreadcrumbs-ol p {
    color: var(--mainTextColor) !important;
}

.MuiBreadcrumbs-ol a,
.MuiBreadcrumbs-ol p.MuiTypography-colorPrimary,
.MuiSvgIcon-colorPrimary {
    color: var(--mainLinkColor) !important;
}

.MuiBreadcrumbs-ol a:hover,
.MuiBreadcrumbs-ol p.MuiTypography-colorPrimary:hover,
.MuiSvgIcon-colorPrimary:hover {
    filter: brightness(110%);
    text-decoration: none !important;
}

.MuiTabs-root .MuiTab-textColorPrimary {
    color: rgba(0, 0, 0, 0.54) !important;
}

.MuiTabs-root .Mui-selected {
    color: var(--themeColor) !important;
    background: rgba(255, 255, 255, 0.2);
}

.MuiTabs-root svg {
    fill: rgba(0, 0, 0, 0.7) !important;
}

.MuiTabs-root svg.currentTab {
    fill: var(--themeColor) !important;
}

.dx-editor-underlined::before,
.dx-editor-outlined::before {
    border-color: var(--mainLinkColor) !important;
}

.dx-datagrid-filter-panel-text,
.dx-icon-filter::before,
.dx-datagrid .dx-link {
    color: var(--themeColor) !important;
}

.dx-datagrid-filter-panel-text:hover,
.dx-icon-filter::before:hover,
.dx-datagrid .dx-link:hover {
    filter: brightness(110%);
    text-decoration: none !important;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row>td {
    font-weight: bold !important;
}

.dx-checkbox-indeterminate .dx-checkbox-icon,
.dx-checkbox-checked .dx-checkbox-icon,
.dx-datagrid-focus-overlay::after {
    background: var(--themeColor) !important;
}

.dx-button.dx-button-default {
    border-radius: 0 !important;
}

.dx-button.dx-button-default:hover {
    filter: brightness(110%);
}

.viewButton {
    margin-top: 0 !important;
    padding: 4px 10px !important;
    font-size: 13px !important;
    width: 200px;
    display: block !important;
    background: var(--themeColor) !important;
}

.viewButton>span {
    text-align: left !important;
    margin: 0 !important;
    padding: 0 24px !important;
    width: 100%;
}

.viewButton.textCenter>span {
    text-align: center !important;
    margin: 0 !important;
    width: 100%;
}

.viewButton:hover {
    filter: brightness(110%);
}

.Autocomplete ::after {
    border-color: var(--themeColor) !important;
}

.dx-datagrid-filter-panel-clear-filter {
    color: var(--themeColor) !important;
}

.MuiBackdrop-root {
    background-color: rgba(255, 255, 255, 0) !important;
    /* prettier-ignore */
    background-image: linear-gradient( to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 48px, rgba(0, 0, 0, 0.5) 48px, rgba(0, 0, 0, 0.5) 0);
}

.MuiDialog-root .MuiPaper-root {
    border: 1px var(--gridBorder) solid !important;
    border-color: var(--midGray) !important;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05) !important;
}

.MuiDialogTitle-root {
    padding: 7px 24px !important;
}

.MuiDialogContent-root {
    flex: 0 0 auto;
    padding: 0 24px 16px 24px !important;
}

.MuiDialogActions-root {
    margin-top: 5px !important;
    padding: 0 24px 16px 24px !important;
}

.dx-popup-bottom.dx-toolbar .dx-toolbar-items-container {
    margin-top: -4px !important;
}

.MuiSelect-icon {
    top: calc(50% - 9px);
}

.MuiPickersModal-dialogRoot .MuiSvgIcon-root {
    fill: #fff !important;
}

.MuiPickersCalendarHeader-iconButton .MuiSvgIcon-root {
    fill: var(--midGray) !important;
}

.MuiPickersModal-dialogRoot .MuiDialogContent-root {
    padding: 0 !important;
}

.MuiPickersModal-dialogRoot .MuiPaper-root {
    border-color: rgba(255, 255, 255, 0.15) !important;
}

.MuiPickersModal-dialogRoot .MuiTabs-scroller>span {
    height: 5px !important;
    background-color: rgba(255, 255, 255, 0.5) !important;
}

.clickable {
    font-size: 13px;
    font-weight: 400;
    text-decoration: none;
    color: var(--themeColor) !important;
}

.clickable:hover {
    cursor: pointer;
}

.dx-pager {
    padding: 13px 0;
}

.dx-pager .dx-pages {
    margin-right: -10px;
}

.dx-pager .dx-page,
.dx-pager .dx-pages .dx-next-button,
.dx-pager .dx-pages .dx-prev-button {
    outline: none !important;
}

.dx-pager .dx-pages .dx-next-button::before,
.dx-pager .dx-pages .dx-prev-button::before {
    color: var(--themeColor) !important;
}

.dx-pager .dx-info,
.dx-pager .dx-page {
    color: var(--mainTextColor) !important;
    opacity: 0.5 !important;
}

.dx-pager .dx-page.dx-selection {
    color: #fff !important;
    background-color: var(--themeColor) !important;
    opacity: 1 !important;
}

.dx-fieldchooser-popup,
.dx-overlay-shader {
    background-color: rgba(255, 255, 255, 0) !important;
    /* prettier-ignore */
    background-image: linear-gradient( to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 48px, rgba(0, 0, 0, 0.5) 48px, rgba(0, 0, 0, 0.5) 0);
}

.dx-datagrid div.dx-loadindicator-segment-inner {
    border-width: 3px;
    border-color: var(--themeColor) var(--themeColor) transparent;
}

:root .light-mode .highlight,
:root .light-mode .dx-datagrid-search-text {
    color: #fff !important;
    background-color: var(--themeColor) !important;
}

:root .dark-mode {
    --mainBackgroundColor: #2b2b2b;
    --gridBackgroundColor: #232323;
    --gridHoverColor: #1a1a1a;
    --mainTextColor: #ccc;
    --subMenuText: #fff;
    --mainLinkColor: var(--themeColor);
    --gridBorder: #64666a;
    color: var(--mainTextColor) !important;
    background: var(--mainBackgroundColor) !important;
}

.Mui-disabled {
    opacity: 0.5 !important;
}

.Mui-disabled .MuiTypography-root {
    color: var(--mainTextColor) !important;
}

.MuiDrawer-paperAnchorRight {
    top: 48px !important;
    height: calc(100% - 48px) !important;
}

:root .dark-mode * {
    color: var(--mainTextColor) !important;
}

.orgChartAutocompletePopper {
    z-index: 500;
}

.orgChartAutocompletePopper>div {
    border-radius: 0;
    border: 1px #e0e0e0 solid !important;
}

@import url('DarkMode.css');