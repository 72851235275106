#root > div {
    margin: 0;
    padding: 0;
    width: 100%;
}

#root main {
    margin-top: 48px;
    width: 100%;
    max-width: 100% !important;
    overflow-x: auto;
}

.dx-pager {
    background-color: #fafafa !important;
}

.mt-3-dashboard {
    margin-top: 3px;
}

.cardGridItem {
    margin-bottom: 10px !important;
    padding: 0 8px;
    height: 80px;
    max-width: 19%;
    flex: 0 0 16%;
}

.tableItem {
    margin-bottom: 10px !important;
    padding: 0 8px;
    height: 80px;
    max-width: 23%;
    flex: 0 0 25%;
}

.tableItem table{
    width: 100%;
}
.divWidth {
    width: 25%;
}
.tableItem td {
    font-size: 17px;
}
.tableItem tr {
    width: 100%;
}

.alignment{
    text-align: right;
}

.detail {
    margin: 0;
    padding: 0;
    height: calc(50vh - 127px);
    width: 100%;
}

.detail-header-link {
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    margin: 0 !important;
    padding: 0;
    width: 100%;
    display: contents;
}

.detail-header-link:hover {
    color: #33b0e5;
}

.donutContainer {
    height: 100%;
    /* margin: 35px; */
    margin-left: 45px;
}

.staging-theme .dashboardDatagrid:last-child {
    margin-top: -16px;
}

@media screen and (max-width: 960px) {
    .dashboardDatagrid .dx-toolbar-after {
        margin-top: -40px;
    }
}

@media screen and (max-width: 960px) {
    .detail {
        min-height: 379px;
    }

    .cardGridItem {
        max-width: 50%;
        flex: 0 0 50%;
    }

    .donutContainer {
        margin: 0 25px 15px 25px;
    }
}

@media screen and (min-width: 1280px) {
    #root {
        overflow: hidden;
    }

    /* #root main {
        overflow-x: auto;
        overflow-y: hidden;
    } */
}

:root .dark-mode .detail-header-link {
    color: var(--mainLinkColor) !important;
}

:root .dark-mode .dxc-item {
    fill: var(--mainTextColor) !important;
}

:root .dark-mode .clickcardbody {
    background: var(--themeColor) !important;
}

:root .dark-mode .clickcardbody .MuiSvgIcon-root {
    fill: #fff !important;
}

:root .dark-mode .cardText {
    background: none !important;
}

:root .dark-mode .cardText span {
    color: #fff !important;
}

:root .dark-mode .cardfooter.MuiTypography-root {
    color: #fff !important;
    background: rgba(0, 0, 0, 0.1) !important;
}

:root .dark-mode .noclickcardbody {
    color: #fff !important;
    background: var(--gridBackgroundColor) !important;
}

:root .dark-mode .noclickcardbody > .cardfooter.MuiTypography-root {
    color: #fff !important;
}

:root .dark-mode .dxc-title text {
    fill: var(--mainTextColor) !important;
}

.MuiGrid-container {
    align-items: stretch;
    justify-content: space-between;
}

.container-padding-top {
    padding-top: 20px;
}

.status-widget {
    display: flex;
}