.formFieldMargin {
    margin: 28px 0px 0px 0px;
}

.formFieldSmallMargin {
    margin: 15px 0px 15px 0px;
}

.fileuploader-container {
    border: 1px solid #d3d3d3;
}

.MuiDialog-root .MuiInputBase-root {
    margin: 0;
    padding: 0;
    min-height: 41px !important;
    box-sizing: border-box !important;
    height: 49px !important;
}

.MuiDialog-root .MuiSelect-selectMenu,
.MuiTextField-root {
    min-height: 41px !important;
    box-sizing: border-box !important;
    margin-top: 0 !important;
    height: 49px !important;
}

.formFieldMargin .MuiInputLabel-outlined,
.formFieldMargin .MuiInputLabel-outlined.MuiInputLabel-shrink,
.formFieldMargin .MuiFormControl-root label {
    transform: translate(0, -25px) scale(1) !important;
    margin-top: 4px;
}

.formFieldMargin .MuiInputBase-root fieldset legend {
    display: none !important;
}

.MuiOutlinedInput-root.MuiAutocomplete-inputRoot {
    padding: 0 !important;
}

.chip,
.MuiChip-root.MuiAutocomplete-tag {
    border-radius: 0 !important;
    margin: 0 0 4px 4px;
    height: 25px !important;
}

.MuiOutlinedInput-input {
    padding: 4px 4px 4px 8px !important;
}

.MuiSelect-root {
    padding: 12px 0px !important;
}

#app-theme div {
    padding-top: 3px;
}

.MuiAutocomplete-option {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.divRightRun button {
    margin-left: 10px;
    float: right;
}
.divRightRun {
    margin-top: 0px;
}

.bottomMargin button {
    margin-bottom: 10px;
}