:root .dark-mode .dx-editor-underlined {
    border-color: var(--mainTextColor) !important;
}

:root .dark-mode .dx-editor-underlined::before {
    border-color: var(--mainLinkColor) !important;
}

:root .dark-mode .dx-texteditor.dx-editor-underlined::after {
    border-color: var(--mainTextColor) !important;
}

:root .dark-mode .dx-list-item,
:root .dark-mode .dx-list-select-all-label,
:root .dark-mode .dx-empty-message {
    color: var(--mainTextColor) !important;
}

:root .dark-mode .dx-list-item.dx-state-focused {
    background: rgba(0, 0, 0, 0.2) !important;
}

:root .dark-mode .dx-list-item:hover,
:root .dark-mode .dx-list-item.dx-state-focused:hover {
    background: var(--gridHoverColor) !important;
}

:root .dark-mode .dx-list-item-selected {
    background: var(--gridHoverColor) !important;
}

:root .dark-mode .dx-list-item-selected > div {
    color: var(--themeColor) !important;
}

:root .dark-mode .MuiPaper-root,
:root .dark-mode .MuiTypography-root {
    color: var(--mainTextColor) !important;
    background: var(--mainBackgroundColor) !important;
    border-color: var(--gridBorder) !important;
}

:root .dark-mode .dx-checkbox-icon,
:root .dark-mode .MuiCheckbox-root svg {
    fill: var(--gridBorder) !important;
    border-color: var(--gridBorder) !important;
}

:root .dark-mode .Mui-checked svg {
    fill: var(--themeColor) !important;
}

:root .dark-mode .MuiButton-root.Mui-disabled {
    color: rgba(255, 255, 255, 0.1) !important;
}

:root .dark-mode .dx-filterbuilder {
    background: var(--gridBackgroundColor);
}

:root .dark-mode .dx-item-content > div,
:root .dark-mode .dx-item-content > span {
    color: var(--mainTextColor);
}

:root .dark-mode .dx-datagrid,
:root .dark-mode .dx-datagrid div,
:root .dark-mode .dx-datagrid table,
:root .dark-mode .dx-datagrid tr,
:root .dark-mode .dx-datagrid td {
    color: var(--mainTextColor) !important;
    border-color: var(--gridBorder) !important;
}

:root .dark-mode .dx-datagrid td {
    color: var(--mainTextColor) !important;
    background: var(--gridBackgroundColor) !important;
    border-color: var(--gridBorder) !important;
}

:root .dark-mode .dx-datagrid td .dx-datagrid-summary-item {
    color: var(--themeColor) !important;
}

:root .dark-mode .dx-scrollable-content {
    background: var(--gridBackgroundColor) !important;
    border-color: var(--gridBorder) !important;
}

:root .dark-mode .dx-popup-normal,
:root .dark-mode .dx-popup-normal .dx-scrollable-content,
:root .dark-mode .dx-popup-normal > .dx-toolbar {
    background: var(--mainBackgroundColor) !important;
    border-color: var(--gridBorder) !important;
}

:root .dark-mode .dx-menu-items-container {
    background: none !important;
}

:root .dark-mode .dx-texteditor-input-container input {
    color: var(--mainTextColor) !important;
}

:root .dark-mode ::-webkit-scrollbar-thumb,
:root .dark-mode .dx-datagrid .dx-scrollable-scroll-content {
    color: rgba(255, 255, 255, 0.25) !important;
    background-color: rgba(255, 255, 255, 0.25) !important;
}

:root .dark-mode .dx-datagrid .dx-header-filter:not(.dx-header-filter-empty),
:root .dark-mode .pivotGrid .dx-header-filter:not(.dx-header-filter-empty) {
    color: var(--themeColor) !important;
}

:root .dark-mode .dx-datagrid .dx-header-filter-empty,
:root .dark-mode .dx-icon,
:root .dark-mode .dx-icon::before,
:root .dark-mode .dx-sort,
:root .dark-mode .dx-sort::before,
:root .dark-mode .pivotGrid .dx-header-filter-empty {
    color: var(--mainTextColor) !important;
    fill: var(--mainTextColor) !important;
}

:root .dark-mode .dx-texteditor-container .dx-icon {
    color: var(--mainTextColor) !important;
}

:root .dark-mode .dx-datagrid .dx-texteditor.dx-state-hover {
    background: var(--gridHoverColor) !important;
}

:root .dark-mode .dx-menu .dx-menu-item {
    background: none !important;
}

:root .dark-mode .boundry > div {
    border-color: var(--gridBorder) !important;
}

:root .dark-mode .MuiAutocomplete-popper,
:root .dark-mode .MuiAutocomplete-listbox,
:root .dark-mode .MuiAutocomplete-listbox div,
:root .dark-mode .Autocomplete div,
:root .dark-mode .orgChartAutocompletePopper > div {
    color: var(--mainTextColor) !important;
    background: var(--mainBackgroundColor) !important;
    border-color: var(--gridBorder) !important;
}

:root .dark-mode .orgChartAutocompletePopper > div div {
    color: var(--mainTextColor) !important;
    background: var(--mainBackgroundColor) !important;
}

:root .dark-mode .orgChartAutocompletePopper > div li li:hover {
    color: var(--mainTextColor) !important;
    background: var(--gridHoverColor) !important;
}

:root .dark-mode .pivotGrid,
:root .dark-mode .pivotGrid > div > div,
:root .dark-mode .pivotGrid > div > table,
:root .dark-mode .pivotGrid > div > table tr,
:root .dark-mode .pivotGrid > div > table tr td {
    color: var(--mainTextColor) !important;
    background: var(--mainBackgroundColor) !important;
    border-color: var(--gridBorder) !important;
}

:root .dark-mode .dx-datagrid tr.dx-freespace-row:hover td,
:root .dark-mode .dx-datagrid tr.dx-freespace-row.dx-state-hover td {
    background: var(--gridBackgroundColor) !important;
}

:root .dark-mode .dx-pivotgrid .dx-pivotgrid-expanded .dx-expand::before,
:root .dark-mode .dx-pivotgrid .dx-pivotgrid-collapsed .dx-expand::before {
    color: var(--mainTextColor) !important;
}

:root .dark-mode .dx-pivotgrid .dx-area-field.dx-area-box {
    background-color: rgba(255, 255, 255, 0.1) !important;
}

:root .dark-mode .dx-pivotgrid .dx-area-field-content {
    color: rgba(255, 255, 255, 0.85) !important;
}

:root .dark-mode .dx-grandtotal span {
    color: var(--themeColor) !important;
    font-weight: 700;
}

:root .dark-mode .dx-empty-area-text {
    color: var(--mainTextColor) !important;
}

:root .dark-mode .MuiAutocomplete-listbox {
    border: 1px var(--gridBorder) solid !important;
}

:root .dark-mode .MuiAutocomplete-option:hover {
    color: var(--mainTextColor) !important;
    background: var(--gridHoverColor) !important;
}

:root .dark-mode .MuiDialog-container > .MuiPaper-root {
    color: var(--mainTextColor) !important;
    background: var(--mainBackgroundColor) !important;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5) !important;
}

:root .dark-mode .MuiDialog-container .MuiTypography-root {
    color: var(--mainTextColor) !important;
}

:root .dark-mode .MuiDialog-container .MuiTypography-root.MuiTypography-h6 {
    color: #fff !important;
}

:root .dark-mode .MuiInput-formControl {
    color: var(--mainTextColor) !important;
    background: var(--mainBackgroundColor) !important;
    border-color: var(--gridBorder) !important;
}

:root .dark-mode .MuiInput-formControl::before {
    border-color: var(--gridBorder) !important;
}

:root .dark-mode .boundry header {
    color: var(--mainTextColor) !important;
    background: var(--mainBackgroundColor) !important;
    border-color: var(--gridBorder) !important;
}

:root .dark-mode .boundry header button svg {
    fill: var(--mainTextColor) !important;
}

:root .dark-mode .boundry input {
    color: var(--mainTextColor) !important;
}

:root .dark-mode .boundry div ::before {
    border-color: var(--gridBorder) !important;
}

:root .dark-mode .boundry .orgChartDrawerCover {
    background: var(--mainBackgroundColor) !important;
}

:root .dark-mode .boundry #drawer-container {
    border-color: var(--gridBorder) !important;
}

:root .dark-mode .boundry #drawer-container > div > div {
    color: var(--mainTextColor) !important;
    background: var(--mainBackgroundColor) !important;
    border-color: var(--gridBorder) !important;
}

.dx-loadpanel-content {
    background: var(--gridHoverColor) !important;
    border-color: var(--gridBorder);
}

:root .dark-mode .dx-datagrid div.dx-loadindicator-segment-inner {
    border-color: var(--themeColor) var(--themeColor) transparent !important;
}

:root .dark-mode .dx-area-icon-filter::before {
    color: var(--mainTextColor) !important;
}

:root .white-theme.dark-mode {
    --menuText-Icons: var(--darkGray);
}

:root .white-theme.dark-mode .leftMenuBar .MuiTypography-root {
    color: #2b2b2b !important;
}

:root .white-theme.dark-mode .leftMenuBar li:hover .MuiListItemText-root span,
:root .white-theme.dark-mode .leftMenuBar li.open .MuiListItemText-root span {
    color: #fff !important;
}

:root .dark-mode .highlight,
:root .dark-mode .dx-datagrid-search-text {
    color: #fff !important;
    background-color: var(--themeColor) !important;
    opacity: 0.8;
}

:root .dark-mode .dx-context-menu .dx-submenu {
    color: var(--mainTextColor) !important;
    background: var(--gridBackgroundColor) !important;
    border-color: var(--gridBorder) !important;
}

:root .dark-mode .dx-context-menu .dx-menu-separator {
    background-color: var(--gridBorder) !important;
}

:root .dark-mode .MuiBadge-badge {
    color: #fff !important;
}

:root .dark-mode .clickable {
    color: var(--themeColor) !important;
}
