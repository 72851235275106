@import url('./core/CommonStyles/Theme.css');

html,
body,
#root {
    height: 100%;
}

#root>div {
    height: 100%;
}

#root>div>div {
    background: var(--mainBackgroundColor) !important;
}

#root>div>div.loading {
    background: none !important;
}


.MuiAppBar-positionFixed {
    z-index: 1201 !important;
}
a {
    font-size: 13px;
    font-weight: 400;
    text-decoration: none;
    color: #03a9f4;
}

.page-content {
    padding: 25px 25px;
}

.MuiDialog-container>.MuiPaper-root {
    background-color: #fff !important;
}

.MuiDialog-paperWidthSm {
    min-width: 500px;
}

.MuiCheckbox-root>span.MuiIconButton-label>.MuiSvgIcon-root {
    color: rgba(0, 0, 0, 0.54) !important;
}

.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    font-size: 13px;
}

.MuiAutocomplete-popper {
    box-sizing: border-box !important;
    font-size: 13px !important;
    border: 1px #e0e0e0 solid !important;
    border-radius: 0 !important;
    background: #fff;
}

.MuiAutocomplete-popper * {
    font-size: 13px !important;
}

.MuiButton-text,
.MuiButton-contained {
    font-size: 13px !important;
    text-transform: uppercase !important;
}

.loading {
    position: absolute;
    left: 50%;
    top: 50%;
}

.MuiButton-text span {
    color: var(--themeColor) !important;
}

.MuiButton-text:hover {
    background: #e0e0e0 !important;
    filter: brightness(110%) !important;
}

.headerIcon,
.notificationIcon {
    margin: 0;
    padding: 0;
    height: 18px;
    width: 18px;
    display: inline-flex;
    fill: var(--midGray);
}

.helpMarginTop{
    margin-top: -7px;
}

.MuiButton-contained span {
    color: #fff !important;
}

.MuiButton-contained:hover {
    background: var(--themeColor) !important;
    filter: brightness(110%) !important;
}

.MuiDialog-container .MuiButton-text,
.MuiDialog-container .MuiButton-contained {
    margin: 0 0 0 10px !important;
}

.MuiDialog-container .ResetLayoutButton {
    margin: 0 !important;
}

.react-draggable {
    background: #fafafa;
    cursor: move !important;
}

.dx-header-row {
    background-color: #fff;
}

.dx-button-content>.dx-icon {
    color: #2d2d2d;
}

.dx-datagrid-header-panel {
    padding: 0 !important;
}

.dx-fileuploader-input {
    padding: 7px 0;
    margin-top: -1px !important;
}

.dx-fileuploader-input-container {
    top: -1px;
}

.dx-fileuploader-file-name {
    max-width: 505px;
    white-space: normal !important;
}

.dx-fileuploader-content>.dx-fileuploader-upload-button {
    display: none;
    cursor: none;
    pointer-events: none;
}

/* Indent Group Headers (outdent really) */

.dx-datagrid .dx-row.dx-group-row td.dx-group-cell {
    transform: translateX(-10px);
}


/* Compact Theme + lowering text in grid cells */

.compact-theme .dx-datagrid *:not(.dx-icon) {
    font-size: 11px !important;
}

.compact-theme .dx-datagrid .dx-row.dx-header-row,
.compact-theme .dx-datagrid .dx-row.dx-filter-row,
.compact-theme .dx-datagrid .dx-row.dx-group-row,
.compact-theme .dx-datagrid .dx-row.dx-data-row {
    height: 24px !important;
}

.compact-theme .dx-datagrid .dx-row>td {
    padding-top: 6px !important;
    padding-bottom: 2px !important;
}


/* Medium Theme + lowering text in grid cells */

.medium-theme .dx-datagrid *:not(.dx-icon) {
    font-size: 12px !important;
}

.medium-theme .dx-datagrid .dx-row.dx-header-row,
.medium-theme .dx-datagrid .dx-row.dx-filter-row,
.medium-theme .dx-datagrid .dx-row.dx-group-row,
.medium-theme .dx-datagrid .dx-row.dx-data-row {
    height: 34px !important;
}

.medium-theme .dx-datagrid .dx-row>td {
    padding-top: 11px !important;
    padding-bottom: 5px !important;
}


/* Spacious Theme + lowering text in grid cells */

.spacious-theme .dx-datagrid *:not(.dx-icon) {
    font-size: 13px !important;
}

.spacious-theme .dx-datagrid .dx-row.dx-header-row,
.spacious-theme .dx-datagrid .dx-row.dx-filter-row,
.spacious-theme .dx-datagrid .dx-row.dx-group-row,
.spacious-theme .dx-datagrid .dx-row.dx-data-row {
    height: 40px !important;
}

.spacious-theme .dx-datagrid .dx-row>td {
    padding-top: 13px !important;
    padding-bottom: 7px !important;
}

.compact-theme .dx-datagrid .dx-row>td .dx-texteditor,
.medium-theme .dx-datagrid .dx-row>td .dx-texteditor,
.spacious-theme .dx-datagrid .dx-row>td .dx-texteditor {
    margin: -10px 0 !important;
}

.compact-theme .dx-datagrid .dx-datagrid-filter-row>td .dx-texteditor,
.medium-theme .dx-datagrid .dx-datagrid-filter-row>td .dx-texteditor,
.spacious-theme .dx-datagrid .dx-datagrid-filter-row>td .dx-texteditor {
    margin: 0 !important;
}

.compact-theme .dx-editor-cell.dx-editor-inline-block::before,
.medium-theme .dx-editor-cell.dx-editor-inline-block::before,
.spacious-theme .dx-editor-cell.dx-editor-inline-block::before {
    padding-top: 0;
    padding-bottom: 0;
}

.compact-theme .pivotGrid *:not(.dx-icon) {
    font-size: 11px !important;
}

.compact-theme .dx-row .dx-pointer-events-target {
    margin: -10px 0 !important;
}

.compact-theme .dx-row input,
.compact-theme .dx-row .dx-placeholder::before {
    font-size: 11px !important;
}

.medium-theme .pivotGrid *:not(.dx-icon) {
    font-size: 12px !important;
}

.medium-theme .dx-row input,
.medium-theme .dx-row .dx-placeholder::before {
    font-size: 12px !important;
}

.spacious-theme .dx-row .dx-pointer-events-target {
    margin: -10px 0 !important;
}

.spacious-theme .pivotGrid *:not(.dx-icon) {
    font-size: 13px !important;
}

.spacious-theme .dx-row input,
.spacious-theme .dx-row .dx-placeholder::before {
    font-size: 13px !important;
}

:root .dark-mode .MuiButton-contained span {
    color: #fff !important;
}

:root .dark-mode .MuiButton-text span {
    color: var(--themeColor) !important;
}

.pivotGrid .dx-pivotgrid-area td.pivotLeftAlign span {
    justify-content: flex-start;
    display: inline-flex;
    transform: translateY(2px);
}

.pivotGrid .dx-pivotgrid-area td.pivotLeftAlign div.dx-expand-icon-container {
    margin-right: 10px;
    margin-left: -11px;
    transform: translateY(-4px);
}

.pivotGrid .dx-pivotgrid-area td.pivotCenterAlign div.dx-expand-icon-container {
    margin-right: 10px;
    margin-left: -11px;
    transform: translateY(1px);
}

.pivotGrid .dx-pivotgrid-area-data td.pivotRightAlign span {
    justify-content: flex-end;
    display: inline-flex;
    transform: translateY(2px);
}

:root .dark-mode .MuiButton-text:hover {
    background: var(--gridHoverColor) !important;
}

:root .dark-mode .MuiButton-text:hover span {
    color: #fff !important;
}

.dx-toolbar-after .dx-searchbox.dx-editor-outlined .dx-placeholder {
    position: absolute;
    left: 20px;
    top: -2px;
}

.dx-toolbar-after .dx-searchbox.dx-editor-outlined .dx-placeholder::before {
    padding-left: 20px;
}